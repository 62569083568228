import { Pipe, PipeTransform } from '@angular/core';
import {
  MultiFamilyContainer,
  SingleFamilyContainer,
} from '@trp/nationaldb/interfaces';

@Pipe({ name: 'containers', standalone: true })
export class ContainersPipe implements PipeTransform {
  transform(
    value: (SingleFamilyContainer | MultiFamilyContainer)[] | null | undefined,
    ifNotAvailableString = 'N/A'
  ) {
    if (!value || value.length === 0) {
      return ifNotAvailableString;
    }
    if (value.length === 1) {
      return capitalize(value[0]);
    }
    return capitalize(
      [
        value.slice(0, value.length - 1).join(', '),
        value[value.length - 1],
      ].join(' & ')
    );
  }
}

function capitalize(str: string) {
  if (!str) {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
}
