import { Injectable } from '@angular/core';
import {
  AcceptedCommunityItem,
  CommunityProfileSections,
  CommunityProfileWidgetConfig,
  CommunityProfileWidgetDefinitionList,
  CommunityProfileWidgetLocation,
  ProgramOrSectionType,
} from '@trp/community/interfaces';
import { CommunityWithVerifications } from '@trp/community/program-verification/interfaces';
import {
  MultiFamilyProgram,
  PrivateCommunityInformation,
} from '@trp/nationaldb/interfaces';
import {
  getProgramVerifiedByInformation,
  getSectionVerifiedByInformation,
} from '@trp/community/program-verification/common';

@Injectable()
export class CommunityWidgetInfoUtilService {
  doesMultiFamilyHaveAdditionalServices(program: MultiFamilyProgram) {
    return [
      program.hasEnforcement,
      program.hasTechnicalSupport,
      program.hasEducation,
      program.hasOrdinance,
    ].some((v) => v);
  }

  filterWidgetDefinitionListByLocation(
    definitionList: CommunityProfileWidgetDefinitionList,
    location: CommunityProfileWidgetLocation,
    isEditMode = false
  ) {
    return definitionList
      .filter((d) => d.locations.includes(location))
      .filter((d) => !isEditMode || d.isReadonly);
  }

  getSectionVerifiableProperties(
    communityProfileWidgetConfig: CommunityProfileWidgetConfig,
    section: Extract<CommunityProfileSections, ProgramOrSectionType>
  ) {
    return communityProfileWidgetConfig?.[section]?.propertiesToVerify ?? [];
  }

  getLastVerifiedInfo(
    communityWithVerifications: CommunityWithVerifications | null | undefined,
    item: AcceptedCommunityItem | null
  ) {
    if (!communityWithVerifications || !item) {
      return null;
    }
    return getProgramVerifiedByInformation(
      item.unifiedCommunityInformation.communityInformation,
      communityWithVerifications.verifications
    );
  }

  getSectionLastVerifiedInfo(
    communityProfileWidgetConfig: CommunityProfileWidgetConfig,
    section: Extract<CommunityProfileSections, ProgramOrSectionType>,
    communityWithVerifications: CommunityWithVerifications | null | undefined,
    community: PrivateCommunityInformation | null
  ) {
    if (!communityWithVerifications || !community) {
      return null;
    }

    const verifiableProperties = this.getSectionVerifiableProperties(
      communityProfileWidgetConfig,
      section
    );

    return getSectionVerifiedByInformation(
      section,
      community,
      communityWithVerifications.verifications,
      verifiableProperties
    );
  }
}
