import {
  DropoffProgram,
  MultiFamilyProgram,
  PrivateCommunityInformation,
  SingleFamilyProgram,
} from '@trp/nationaldb/interfaces';

export const hasSingleFamily = (info: PrivateCommunityInformation) =>
  (info.singleFamilyPrograms ?? []).length > 0;
export const hasMultiFamily = (info: PrivateCommunityInformation) =>
  (info.multiFamilyPrograms ?? []).length > 0;
export const poundsPerHousehold = (
  info: DropoffProgram | MultiFamilyProgram | SingleFamilyProgram
) => info.poundsPerHousehold ?? (info.tonnage! * 2000) / info.householdsServed!;

const measurementYear = (
  info: MultiFamilyProgram | SingleFamilyProgram
): string =>
  info.poundsPerHouseholdYear
    ? `in ${
        info.poundsPerHouseholdYearType === 'Other'
          ? ''
          : `${info.poundsPerHouseholdYearType?.toLowerCase()} year `
      }${info.poundsPerHouseholdYear}`
    : 'Year measured not available';

export const measurementPeriod = (
  info: MultiFamilyProgram | SingleFamilyProgram | DropoffProgram
): string => {
  // temporary workaround because backend sends pphReportingPeriodStart instead of poundsPerHouseholdReportingPeriodStart
  const poundsPerHouseholdReportingPeriodStart =
    info.poundsPerHouseholdReportingPeriodStart ||
    (info as any).pphReportingPeriodStart;
  const poundsPerHouseholdReportingPeriodEnd =
    info.poundsPerHouseholdReportingPeriodEnd ||
    (info as any).pphReportingPeriodEnd;
  if (
    poundsPerHouseholdReportingPeriodStart &&
    poundsPerHouseholdReportingPeriodEnd
  ) {
    const startDate = new Date(poundsPerHouseholdReportingPeriodStart);
    const endDate = new Date(poundsPerHouseholdReportingPeriodEnd);

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    const startStr = startDate.toLocaleDateString('en-US', options);
    const endStr = endDate.toLocaleDateString('en-US', options);

    return `${startStr} - ${endStr}`;
  } else {
    return 'Reporting Period unknown';
  }
};
